import { InfoCircleOutlined } from '@ant-design/icons';
import { UseDrawerFormReturnType } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { Form, Grid, Popconfirm } from 'antd';
import { DrawerWithButtons } from 'common/components';
import { DeleteIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';
import { BankAccountCreateContainer } from 'src/resources/bank-account/bank-account-form/bank-account-form.container';
import { ItemDrawer } from 'src/resources/item';
import { SupplierCreateContainer } from 'src/resources/supplier/supplier-create/supplier-create.container';
import { BlueColors } from 'theme';

import { PurchaseDetailModel, PurchaseRequestModel } from '../purchase.model';
import { PurchaseCreateTestId } from '../purchase-test-id.constants';
import { BoxPayed } from './box-payed/box-payed.component';
import { usePurchaseCreate } from './purchase-create.hook';
import { usePurchaseCreateStore } from './purchase-create.store';
import * as Styled from './purchase-create.style';
import { PurchaseCreateDetails } from './purchase-create-details/purchase-create-details.component';
import { PurchaseCreateItems } from './purchase-create-items/purchase-create-items.component';
import TotalCost from './total-cost/total-cost.component';

interface PurchaseCreateProps {
  drawerForm: UseDrawerFormReturnType<
    PurchaseDetailModel,
    HttpError,
    PurchaseRequestModel
  >;
  isReadonly?: boolean;
}

export const PurchaseCreateContainer = ({
  drawerForm,
  isReadonly,
}: PurchaseCreateProps) => {
  const {
    drawerProps,
    formProps,
    form: formInstance,
    close: onClose,
    saveButtonProps: onCreate,
    formLoading,
    queryResult,
  } = drawerForm ?? {};
  const isLoading = queryResult?.isFetching;

  const {
    initialValues,
    disableItemsTab,
    itemLength,
    handleOnFinish,
    handleOnClickDelete,
    percentBilled,
    percentReceived,
    createSupplierDrawer,
    handleOpenSupplierDrawer,
    createItemDrawer,
    setResourceCreateItem,
    handleOpenItemDrawer,
    createBankAccountDrawer,
    handleOpenBankAccountDrawer,
    grandTotal,
    isOtherCurrency,
    exchangeRate,
  } = usePurchaseCreate(formInstance, formProps, drawerProps);
  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();
  const { showItems, setShowItems } = usePurchaseCreateStore();

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      zIndex={1001}
      title={initialValues?.farmName ?? t('purchase_create.title')}
      buttonSaveProps={{
        ...onCreate,
        onClick: showItems ? onCreate.onClick : () => setShowItems(true),
      }}
      disabledSave={showItems ? false : disableItemsTab && !isReadonly}
      onClose={onClose}
      destroyOnClose
      tagTrackClose="Purchase"
      isLoading={formLoading ?? isLoading}
      isLoadingChildren={isLoading && !initialValues}
      onBack={showItems ? () => setShowItems(false) : undefined}
      textSave={!showItems ? t('buttons.nextStep') ?? '' : undefined}
      moreButtons={
        isReadonly &&
        initialValues?.status?.includes('To Receive') &&
        percentBilled === 0 &&
        percentReceived === 0
          ? [
              <Popconfirm
                key="button_delete"
                title={t('purchase.table.delete_title')}
                description={t('purchase.table.delete_description')}
                onConfirm={() => handleOnClickDelete(initialValues.id)}
                okText={t('buttons.remove')}
                okButtonProps={{ danger: true }}
                cancelText={t('buttons.cancel')}
              >
                <Styled.Button
                  danger
                  icon={<DeleteIcon />}
                  data-testid="purchase-cancel-btn"
                >
                  {t('purchase.table.delete_title')}
                </Styled.Button>
              </Popconfirm>,
            ]
          : [
              <TotalCost
                key="total_cost"
                isOtherCurrency={isOtherCurrency}
                grandTotal={grandTotal}
                exchangeRate={exchangeRate}
                isReadonly={isReadonly}
              />,
            ]
      }
    >
      <SupplierCreateContainer
        supplierDrawerForm={createSupplierDrawer}
        breadcrumbList={[t('purchase_create.breadcrumb')]}
      />
      <ItemDrawer
        useDrawerForm={createItemDrawer}
        setResourceCreate={setResourceCreateItem}
        breadcrumbList={[t('purchase_create.breadcrumb')]}
      />
      <BankAccountCreateContainer
        bankAccountDrawerForm={createBankAccountDrawer}
        breadcrumbList={[t('purchase_create.breadcrumb')]}
      />
      <Styled.BoxButtons
        data-testid={PurchaseCreateTestId.PURCHASE_CREATE_BUTTONS}
      >
        <Styled.ButtonSelected
          onClick={() => setShowItems(false)}
          type={showItems ? undefined : 'primary'}
        >
          {t('purchase_create.details.title')}
        </Styled.ButtonSelected>
        <Styled.ButtonSelected
          onClick={() => setShowItems(true)}
          type={showItems ? 'primary' : undefined}
          disabled={disableItemsTab && !isReadonly}
        >
          {t('purchase_create.items.title')}
          <Styled.BoxAmount isSelected={showItems}>
            {itemLength}
          </Styled.BoxAmount>
        </Styled.ButtonSelected>
      </Styled.BoxButtons>
      <BoxPayed
        purchaseOrder={initialValues}
        show={!!isReadonly && !showItems}
      />
      <Form
        layout="vertical"
        {...formProps}
        onFinish={handleOnFinish}
        data-testid={PurchaseCreateTestId.PURCHASE_CREATE_FORM}
        disabled={isReadonly}
        initialValues={initialValues}
      >
        <PurchaseCreateDetails
          isReadonly={isReadonly}
          formProps={formProps}
          handleOpenSupplierDrawer={handleOpenSupplierDrawer}
          handleOpenBankAccountDrawer={handleOpenBankAccountDrawer}
        />
        <PurchaseCreateItems
          isReadonly={isReadonly}
          handleOpenItemDrawer={handleOpenItemDrawer}
        />
      </Form>
      {(percentBilled !== 0 || percentReceived !== 0) &&
        isReadonly &&
        !showItems && (
          <Styled.DeleteInfoAlert
            data-testid={PurchaseCreateTestId.PURCHASE_CANCEL_ALERT_INFO}
            message={t('purchase_create.infos.cancel_info')}
            showIcon
            icon={
              <InfoCircleOutlined
                style={{ color: BlueColors[60], fontSize: 24 }}
              />
            }
            type="info"
          />
        )}
    </DrawerWithButtons>
  );
};
