import { Typography } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

interface ContainerProps {
  isFullWidth?: boolean;
}

interface BoxIconProps {
  isBlue?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '50%')};
`;

const Title = styled(Typography.Text)`
  color: ${NeutralColors[60]};
  font-weight: 400 !important;
`;

const ValueText = styled(Typography.Text)`
  display: flex;
  align-items: center;
  color: ${NeutralColors[100]};
  font-weight: 400 !important;
`;

const ComplementaryText = styled(Typography.Text)`
  display: flex;
  align-items: center;
  color: ${NeutralColors[60]};
  font-weight: 400 !important;
`;

const BoxIcon = styled.div<BoxIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isBlue }) =>
    isBlue ? BlueColors[10] : NeutralColors[10]};
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 4px;

  svg {
    width: 18px;
    fill: ${({ isBlue }) =>
      isBlue ? BlueColors[60] : NeutralColors[60]} !important;
  }

  path {
    fill: ${({ isBlue }) =>
      isBlue ? BlueColors[60] : NeutralColors[60]} !important;
  }
`;

export { BoxIcon, ComplementaryText, Container, Title, ValueText };
