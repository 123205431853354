import { SearchPageTemplate, SquareSkeleton } from 'common/components';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useTrackingEnrichProperties } from 'common/utils/tracking/tracking.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EntitlementEnum from '../entitlements.enum';
import { useOrganization } from '../organization.provider';
import FarmCardComponent from './card/farm-card.component';
import FarmEmptyStateComponent from './empty-state/farm-empty-state.component';
import { Farm } from './farm.model';
import { useFarm } from './farm.provider';

const FarmContainer = () => {
  const { t } = useTranslation();
  const { setCurrentOrganizationId, currentOrganizationId, entitlements } =
    useOrganization();
  const { farms, setCurrentFarmId, isLoading, currentFarmId } = useFarm();
  const [filteredFarms, setFilteredFarms] = useState<Farm[]>([]);
  const navigate = useNavigateWithOrgAndFarm();

  useTrackingEnrichProperties(); // Enrich tracking properties with current organization and farm

  const hasFarms = useMemo(
    () => farms.length > 0 && !isLoading,
    [farms.length, isLoading],
  );

  const showEmptyState = useMemo(
    () => !isLoading && farms.length === 0,
    [farms.length, isLoading],
  );

  useEffect(() => {
    if (farms.length) {
      setFilteredFarms(farms);
    }

    if (farms.length === 1) {
      setCurrentFarmId(farms[0].id);
    }
  }, [farms, setFilteredFarms]);

  const handleOnSearch = useCallback(
    (value: string) => {
      const filteredFarms = farms.filter((farm) =>
        farm.name.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredFarms(filteredFarms);
    },
    [farms],
  );

  const handleOnBackClick = useCallback(() => {
    setCurrentOrganizationId(null);
    navigate(ROUTES.ORGANIZATION, null);
  }, [navigate, setCurrentOrganizationId]);

  useEffect(() => {
    if (currentFarmId) {
      navigate(
        entitlements.includes(EntitlementEnum.LANDING_PAGE)
          ? ROUTES.DASHBOARD
          : ROUTES.PURCHASE,
        currentOrganizationId,
        currentFarmId,
      );
    }
  }, [currentFarmId, currentOrganizationId, navigate, entitlements]);

  return (
    <SearchPageTemplate
      title={t('farms.title')}
      onBack={handleOnBackClick}
      onSearch={handleOnSearch}
    >
      {isLoading && <SquareSkeleton width="300px" height="65px" />}
      {hasFarms &&
        filteredFarms.map((farm) => (
          <FarmCardComponent
            key={farm.id}
            farm={farm}
            onClick={() => setCurrentFarmId(farm.id)}
          />
        ))}
      {showEmptyState && <FarmEmptyStateComponent />}
    </SearchPageTemplate>
  );
};

export { FarmContainer as default, FarmContainer };
