import styled from 'styled-components';
import { NeutralColors } from 'theme';

const BoxPayed = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid ${NeutralColors[20]};
  margin: 16px 0;
  border-radius: 12px;
  padding: 16px;
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
`;

export { BoxPayed, Flex };
