import { useSelect } from '@refinedev/antd';
import { useOne } from '@refinedev/core';
import { FormListFieldData } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { convertSelectPropsToOptionType } from 'common/components';
import { useEffect, useMemo, useState } from 'react';
import { useOrganization } from 'src/organization';
import { ItemDetailPurchaseModel } from 'src/resources/item';
import {
  IsGroupWarehouseEnum,
  IsStockItemEnum,
} from 'src/stock/warehouse-list/warehouse-list.constant';

import { usePurchaseCreateStore } from '../../purchase-create.store';

const usePurchaseCreateItem = (
  itemFieldData: FormListFieldData,
  amountItems: number,
  index: number,
) => {
  const [isOpen, setIsOpen] = useState(true);
  const { currency } = usePurchaseCreateStore();
  const formInstance = useFormInstance();
  const itemCode = useWatch(['items', itemFieldData.name, 'itemCode']);
  const isOtherCurrency = useWatch(['isOtherCurrency']);
  const initialExchangeRateOtherCurrency = useWatch([
    'initialExchangeRateOtherCurrency',
  ]);
  const itemQty = useWatch(['items', itemFieldData.name, 'qty']);
  const itemRate = useWatch(['items', itemFieldData.name, 'rate']);
  const itemWarehouse = useWatch(['items', itemFieldData.name, 'warehouse']);
  const receivedQty = useWatch(['items', itemFieldData.name, 'receivedQty']);
  const farmName = useWatch(['farmName']);
  const { currentOrganizationId } = useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  useEffect(() => {
    setIsOpen(amountItems <= index + 1);
  }, [amountItems, index]);

  const { data, isFetching: isFetchingItem } = useOne<ItemDetailPurchaseModel>({
    resource: 'item',
    id: itemCode,
    meta: {
      organizationId: currentOrganizationId,
      parent: 'purchase-details',
      params: {
        farm: farmName,
        doctype: 'Purchase Order',
        currency,
      },
    },
    queryOptions: {
      enabled: !!itemCode && !!farmName && !!currency,
    },
  });

  const item = data?.data;

  useEffect(() => {
    if (item?.lastPurchaseRate) {
      const lastPurchaseRate = isOtherCurrency
        ? item.lastPurchaseRate / initialExchangeRateOtherCurrency
        : item.lastPurchaseRate;
      formInstance?.setFieldValue(
        ['items', itemFieldData.name, 'rate'],
        itemRate > 0 ? itemRate : lastPurchaseRate,
      );
    }
    if (item?.warehouse) {
      formInstance?.setFieldValue(
        ['items', itemFieldData.name, 'warehouse'],
        item.warehouse,
      );
    }
  }, [
    formInstance,
    itemFieldData.name,
    item?.lastPurchaseRate,
    item?.warehouse,
  ]);

  const { selectProps: itemSelectProps, queryResult: itemQueryResult } =
    useSelect({
      resource: 'item',
      optionLabel: 'name',
      filters: [
        {
          field: 'isStockItem',
          operator: 'eq',
          value: IsStockItemEnum.YES,
        },
      ],
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      meta: metadata,
      queryOptions: {
        enabled: !!metadata.organizationId,
      },
    });

  const optionsItemSelect = useMemo(
    () =>
      convertSelectPropsToOptionType(
        itemSelectProps,
        itemQueryResult,
        'category',
      ),
    [itemSelectProps, itemQueryResult],
  );

  const { selectProps: warehouseSelectProps } = useSelect({
    resource: 'warehouse',
    optionLabel: 'name',
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: farmName,
      },
      {
        field: 'isGroup',
        operator: 'eq',
        value: IsGroupWarehouseEnum.NO,
      },
    ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
  });

  return {
    item,
    itemQty,
    itemRate,
    receivedQty,
    itemWarehouse,
    isFetchingItem,
    isOpen,
    itemSelectProps: {
      ...itemSelectProps,
      options: optionsItemSelect,
    },
    setIsOpen,
    warehouseSelectProps,
  };
};

export { usePurchaseCreateItem as default, usePurchaseCreateItem };
