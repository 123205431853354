import { Select, Space, Switch, Typography } from 'antd';
import { FormItem } from 'common/components';
import { getCurrencySymbol } from 'common/utils';
import { useTranslation } from 'react-i18next';
import OtherCurrenciesHelpButton from 'src/orders-and-payments/purchase/other-currencies-help/other-currencies-help-button.component';
import { OtherCurrenciesHelpModal } from 'src/orders-and-payments/purchase/other-currencies-help/other-currencies-help-modal.component';

import { useOtherCurrencies } from './other-currencies.hook';
import * as Styled from './other-currencies.style';

interface OtherCurrenciesProps {
  isShow: boolean;
}

const OtherCurrencies = ({ isShow }: OtherCurrenciesProps) => {
  const { t } = useTranslation();

  const {
    otherCurrencySelectProps,
    isOtherCurrency,
    renderCustomLabel,
    farmCurrency,
    handleOpenOtherCurrenciesHelp,
    otherCurrenciesModalProps,
  } = useOtherCurrencies();

  return (
    <Styled.PaymentBox isOpen={isOtherCurrency} isShow={isShow}>
      <Styled.OtherCurrenciesWrapper>
        <Styled.SwitchBox>
          <Space.Compact direction="vertical">
            <Typography>
              {t('purchase_create.details.is_other_currency')}
            </Typography>
            <Typography>
              {t('purchase_create.details.is_other_currency_example')}
            </Typography>
          </Space.Compact>
          <Styled.SwitchForm
            name={['isOtherCurrency']}
            valuePropName={'checked'}
          >
            <Switch
              datatest-id="is-other-currency-switchbox"
              checkedChildren={t('buttons.yes')}
              unCheckedChildren={t('buttons.no')}
            />
          </Styled.SwitchForm>
        </Styled.SwitchBox>
        {isOtherCurrency && (
          <>
            <Styled.FormItem
              label={t('purchase_create.details.initial_exchange_rate')}
              required
            >
              <Styled.Space>
                <FormItem
                  name={'initialExchangeRateOtherCurrency'}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  noStyle
                >
                  <Styled.CustomInputMoney
                    prefix={getCurrencySymbol(farmCurrency)}
                  />
                </FormItem>
                <FormItem
                  name={'typeOtherCurrency'}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  noStyle
                >
                  <Select
                    {...otherCurrencySelectProps}
                    labelRender={renderCustomLabel}
                    style={{ width: '40%' }}
                  />
                </FormItem>
              </Styled.Space>
            </Styled.FormItem>

            <OtherCurrenciesHelpButton
              onClick={handleOpenOtherCurrenciesHelp}
            />
            <OtherCurrenciesHelpModal modalProps={otherCurrenciesModalProps} />
          </>
        )}
      </Styled.OtherCurrenciesWrapper>
    </Styled.PaymentBox>
  );
};

export { OtherCurrencies as default, OtherCurrencies };
