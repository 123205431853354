import { Space, Typography } from 'antd';
import { getCurrencyValue } from 'common/utils/currency.util';
import { useTranslation } from 'react-i18next';

import { usePurchaseCreateStore } from '../purchase-create.store';

interface TotalCostProps {
  grandTotal: number;
  isOtherCurrency: boolean;
  exchangeRate: number;
  isReadonly?: boolean;
}

const TotalCost = ({
  grandTotal = 0,
  isOtherCurrency,
  exchangeRate = 0,
  isReadonly,
}: TotalCostProps) => {
  const { t, i18n } = useTranslation();
  const { customCurrency, currency } = usePurchaseCreateStore();

  const handleMainValue = () => {
    if (isOtherCurrency) {
      if (customCurrency?.isMonetary) {
        return `${customCurrency?.symbol} ${grandTotal.toLocaleString(
          i18n.language,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        )}`;
      }
      return `${grandTotal.toLocaleString(i18n.language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })} ${customCurrency?.symbol}`;
    }
    return getCurrencyValue(grandTotal, currency, i18n.language);
  };

  const handleSecondaryValue = () => {
    if (isOtherCurrency) {
      return ` • ${getCurrencyValue(
        exchangeRate * grandTotal,
        currency,
        i18n.language,
      )}`;
    }
  };

  return (
    !isReadonly && (
      <Space.Compact direction="vertical">
        <Typography.Text strong>
          {t('purchase_create.grand_total')}
          {handleSecondaryValue()}
        </Typography.Text>
        <Typography.Text strong>{handleMainValue()}</Typography.Text>
      </Space.Compact>
    )
  );
};

export { TotalCost as default, TotalCost };
