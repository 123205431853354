import { ReactNode } from 'react';

import * as Styled from './container-icon-value.style';

interface ContainerIconValueProps {
  title: string;
  Icon: ReactNode;
  value: ReactNode;
  complement?: ReactNode;
  isBlue?: boolean;
  isFullWidth?: boolean;
}

const ContainerIconValue = ({
  title,
  Icon,
  value,
  complement,
  isBlue = true,
  isFullWidth,
}: ContainerIconValueProps) => {
  return (
    <Styled.Container isFullWidth={isFullWidth}>
      <Styled.Title>{title}</Styled.Title>
      <Styled.ValueText>
        <Styled.BoxIcon isBlue={isBlue}>{Icon}</Styled.BoxIcon>
        {value}
      </Styled.ValueText>
      <Styled.ComplementaryText>{complement}</Styled.ComplementaryText>
    </Styled.Container>
  );
};

export { ContainerIconValue };
