import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import * as Styled from './other-currencies-help-button.style';

interface OtherCurrenciesHelpButtonProps {
  onClick?: () => void;
}

const OtherCurrenciesHelpButton = ({
  onClick,
}: OtherCurrenciesHelpButtonProps) => {
  const { t } = useTranslation();
  return (
    <Typography.Link onClick={onClick}>
      <Space>
        <Styled.CustomHelpIcon />
        {t('purchase_create.details.other_currency_help')}
      </Space>
    </Typography.Link>
  );
};

export { OtherCurrenciesHelpButton as default, OtherCurrenciesHelpButton };
