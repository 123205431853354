import { Typography } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Text = styled(Typography.Text)`
  color: ${NeutralColors[60]};
`;

const ContainerIconValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BoxIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: ${BlueColors[10]};
  width: 26px;
  height: 26px;

  svg {
    width: 18px;
    fill: ${BlueColors[60]};
  }
`;

const TextValue = styled(Typography.Text)`
  color: ${NeutralColors[100]};
  font-size: 20px;
  font-weight: 600;
`;

export { BoxIcon, Container, ContainerIconValue, Text, TextValue };
