import { Grid, Modal, ModalProps, Space, Typography } from 'antd';
import {
  AutoFixHighIcon,
  DollarIcon,
  PurchaseIcon,
  StackedBarChartIcon,
} from 'common/icons';
import { useTranslation } from 'react-i18next';

import { IconWrapper } from '../purchase-payment/purchase-payment-detail/purchase-payment-detail.style';

interface OtherCurrenciesHelpModalProps {
  modalProps: ModalProps;
}

const OtherCurrenciesHelpModal = ({
  modalProps,
}: OtherCurrenciesHelpModalProps) => {
  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();
  return (
    <Modal
      {...modalProps}
      title={t('purchase.other_currencies_help.title')}
      width={breakpoint.sm ? '750px' : '100%'}
      footer={null}
    >
      <Space align="start">
        <IconWrapper>
          <PurchaseIcon />
        </IconWrapper>
        <Typography.Paragraph>
          {t('purchase.other_currencies_help.paragraph_1')}
        </Typography.Paragraph>
      </Space>
      <Space align="start">
        <IconWrapper>
          <DollarIcon />
        </IconWrapper>
        <Typography.Paragraph>
          {t('purchase.other_currencies_help.paragraph_2')}
        </Typography.Paragraph>
      </Space>
      <Space align="start">
        <IconWrapper>
          <AutoFixHighIcon />
        </IconWrapper>
        <Typography.Paragraph>
          {t('purchase.other_currencies_help.paragraph_3')}
        </Typography.Paragraph>
      </Space>
      <Space align="start">
        <IconWrapper>
          <StackedBarChartIcon />
        </IconWrapper>
        <Typography.Paragraph>
          {t('purchase.other_currencies_help.example.title')}
          <br />
          {t('purchase.other_currencies_help.example.paragraph_1')}
          <br />
          {t('purchase.other_currencies_help.example.paragraph_2')}
        </Typography.Paragraph>
      </Space>
    </Modal>
  );
};

export { OtherCurrenciesHelpModal as default, OtherCurrenciesHelpModal };
