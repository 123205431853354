import { useOne } from '@refinedev/core';
import { Divider, Skeleton, Typography } from 'antd';
import { CurrencyExchangeIcon, MoneyIcon } from 'common/icons';
import { getCurrencyValue } from 'common/utils/currency.util';
import { useTranslation } from 'react-i18next';

import { ContainerIconValue } from '../../container-icon-value/container-icon-value.component';
import { useOtherCurrenciesHelp } from '../../other-currencies-help/other-currencies-help.hook';
import OtherCurrenciesHelpButton from '../../other-currencies-help/other-currencies-help-button.component';
import OtherCurrenciesHelpModal from '../../other-currencies-help/other-currencies-help-modal.component';
import { PurchaseFormModel } from '../../purchase.model';
import * as Styled from './success-other-currency.style';

interface SuccessOtherCurrencyProps {
  purchaseOrderId?: string;
}

const SuccessOtherCurrency = ({
  purchaseOrderId,
}: SuccessOtherCurrencyProps) => {
  const { i18n, t } = useTranslation();
  const { data, isFetching } = useOne<PurchaseFormModel>({
    resource: 'purchase-order',
    id: purchaseOrderId,
    queryOptions: {
      enabled: !!purchaseOrderId,
    },
  });

  const { handleOpenOtherCurrenciesHelp, modalProps } =
    useOtherCurrenciesHelp();

  if (!data || isFetching) {
    return <Skeleton active />;
  }

  const paidOtherCurrency =
    (data.data.valueOtherCurrency ?? 0) *
    (data.data.initialExchangeRateOtherCurrency ?? 0);

  return (
    <Styled.Container>
      <ContainerIconValue
        Icon={<MoneyIcon />}
        title={t('purchase_payment.success_other_currency.initial_value')}
        value={getCurrencyValue(
          paidOtherCurrency,
          data.data.currency,
          i18n.language,
        )}
        isBlue={false}
        isFullWidth
      />
      <ContainerIconValue
        Icon={<MoneyIcon />}
        title={t('purchase_payment.success_other_currency.total_billed')}
        value={getCurrencyValue(
          data.data.grandTotal ?? 0,
          data.data.currency,
          i18n.language,
        )}
        isBlue={false}
        isFullWidth
      />
      <ContainerIconValue
        Icon={<MoneyIcon />}
        title={t('purchase_payment.success_other_currency.settled')}
        value={getCurrencyValue(
          data.data.paidAmount ?? 0,
          data.data.currency,
          i18n.language,
        )}
        isBlue={false}
        isFullWidth
      />
      <Divider style={{ margin: 0 }} />
      <div>
        <Styled.Text>
          {t('purchase_payment.success_other_currency.diff')}
        </Styled.Text>
        <Styled.ContainerIconValue>
          <Styled.BoxIcon>
            <CurrencyExchangeIcon />
          </Styled.BoxIcon>
          <Styled.TextValue>
            {getCurrencyValue(
              data.data.diffValueOtherCurrency ?? 0,
              data.data.currency,
              i18n.language,
            )}
          </Styled.TextValue>
        </Styled.ContainerIconValue>
      </div>
      <Typography.Text>
        {t('purchase_payment.success_other_currency.description')}
      </Typography.Text>
      <OtherCurrenciesHelpButton onClick={handleOpenOtherCurrenciesHelp} />
      <OtherCurrenciesHelpModal modalProps={modalProps} />
    </Styled.Container>
  );
};

export { SuccessOtherCurrency };
