import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { valueType } from 'antd/es/statistic/utils';
import { useMemo } from 'react';
import {
  hasMoreThanDecimalPlaces,
  removeDecimalPlaces,
} from 'src/orders-and-payments/purchase/purchase.util';

const usePurchaseCreateItemDetails = (name: number) => {
  const form = useFormInstance();
  const rate = useWatch(['items', name, 'rate']);
  const qty = useWatch(['items', name, 'qty']);
  const totalValue = useWatch(['items', name, 'totalValue']);
  const isOtherCurrency = useWatch('isOtherCurrency');
  const exchangeRate = useWatch('initialExchangeRateOtherCurrency');

  const handleOnChangeQty = (value: valueType | null) => {
    if (value && rate) {
      form.setFieldValue(['items', name, 'totalValue'], Number(value) * rate);
    }
  };

  const handleOnChangeRate = (value: valueType | null) => {
    if (value && qty) {
      form.setFieldValue(['items', name, 'totalValue'], Number(value) * qty);
    }
  };

  const handleOnChangeTotalValue = (value: valueType | null) => {
    if (value && qty) {
      form.setFieldValue(['items', name, 'rate'], Number(value) / qty);
    }
  };

  const newTotalValue = useMemo(
    () => qty * removeDecimalPlaces(rate),
    [qty, rate],
  );

  const hasMoreDecimalPlaces = useMemo(
    () => hasMoreThanDecimalPlaces(totalValue, newTotalValue),
    [rate, qty, newTotalValue],
  );

  const handleUpdateTotalValue = () => {
    form.setFieldValue(['items', name, 'rate'], removeDecimalPlaces(rate));
    form.setFieldValue(['items', name, 'totalValue'], newTotalValue);
  };

  return {
    isOtherCurrency,
    exchangeRate,
    rate,
    totalValue,
    newTotalValue,
    hasMoreDecimalPlaces,
    handleOnChangeQty,
    handleOnChangeRate,
    handleOnChangeTotalValue,
    handleUpdateTotalValue,
  };
};

export { usePurchaseCreateItemDetails };
