import { Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { InvoiceReceiptTableModel } from '../invoice-receipt-table.model';

interface StatusColumnProps {
  data: InvoiceReceiptTableModel;
}

const StatusColumn = ({ data }: StatusColumnProps) => {
  const { t } = useTranslation();
  const { type, isCancelled, account, updateBy } = data;

  const handleTagColor = (type: string) => {
    switch (type) {
      case 'invoice':
      case 'payment':
        return 'red';
      case 'receipt':
        return 'blue';
      default:
        return 'default';
    }
  };

  return (
    <Tooltip
      title={t(`purchase.invoice_receipt.status.tooltip_${type}`, {
        accountName: account,
        userName: updateBy,
      })}
    >
      <Tag color={isCancelled ? 'default' : handleTagColor(type)}>
        {t(
          `purchase.invoice_receipt.status.${type}${
            isCancelled ? '_cancelled' : ''
          }`,
        )}
      </Tag>
    </Tooltip>
  );
};

export { StatusColumn as default, StatusColumn };
