import { DeleteOutlined } from '@ant-design/icons';
import { UseDrawerFormReturnType } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { Button, Form, Grid, Popconfirm } from 'antd';
import { DrawerWithButtons } from 'common/components';
import { CancelledBy } from 'common/components/cancelled-by/cancelled-by.component';
import { useTranslation } from 'react-i18next';
import { ItemDrawer } from 'src/resources/item';
import { SupplierCreateContainer } from 'src/resources/supplier/supplier-create/supplier-create.container';

import { InvoiceDetailModel, InvoiceFormModel } from '../invoice.model';
import InvoiceFormDetails from './details/invoice-form-details.component';
import useInvoiceForm from './invoice-form.hook';
import { useInvoiceFormStore } from './invoice-form.store';
import * as Styled from './invoice-form.style';
import InvoiceFormItems from './items/invoice-form-items.component';

interface InvoiceContainerProps {
  drawerForm: UseDrawerFormReturnType<
    InvoiceDetailModel,
    HttpError,
    InvoiceFormModel
  >;
}

export const InvoiceFormContainer = ({ drawerForm }: InvoiceContainerProps) => {
  const {
    drawerProps,
    formProps,
    form: formInstance,
    close: onClose,
    saveButtonProps: onSubmitForm,
    formLoading,
  } = drawerForm ?? {};
  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();

  const {
    disableItemsTab,
    detailTabClassname,
    itemsTabClassname,
    isPaidPartial,
    isCancelled,
    initialValues,
    itemsAmount,
    handleOnFinish,
    handleOnClose,
    handleOnDelete,
    createItemDrawer,
    setResourceCreateItem,
    handleOpenItemDrawer,
    createSupplierDrawer,
    handleOpenSupplierDrawer,
  } = useInvoiceForm(formProps, drawerProps, formInstance, onClose);

  const isEdit = !!initialValues.id;

  const { disableSave, setShowItems, showItems } = useInvoiceFormStore();

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      title={
        initialValues.id
          ? t('invoice.form.edit_title')
          : t('invoice.form.title')
      }
      buttonSaveProps={{
        ...onSubmitForm,
        onClick: showItems ? onSubmitForm.onClick : () => setShowItems(true),
      }}
      disabledSave={showItems ? disableSave : disableItemsTab}
      isLoading={formLoading}
      popConfirmSave={
        isPaidPartial && showItems
          ? {
              placement: 'topRight',
              title: t('invoice.form.edit_title'),
              description: (
                <Styled.EditPopConfirmText>
                  {t('invoice.form.edit_description')}
                </Styled.EditPopConfirmText>
              ),
              okText: t('buttons.yes'),
              cancelText: t('buttons.no'),
            }
          : undefined
      }
      isLoadingChildren={formLoading && !formProps.initialValues}
      onClose={handleOnClose}
      tagTrackClose="Item Category"
      moreButtons={
        initialValues.id && !isCancelled
          ? [
              <Popconfirm
                key="button_delete"
                title={t('invoice.table.delete_title')}
                description={
                  isPaidPartial
                    ? t('invoice.table.delete_description_paid_partial')
                    : t('invoice.table.delete_description')
                }
                onConfirm={() => handleOnDelete(initialValues.id)}
                okText={t('buttons.yes')}
                cancelText={t('buttons.no')}
              >
                <Button danger icon={<DeleteOutlined />}>
                  {t('invoice.table.delete_title')}
                </Button>
              </Popconfirm>,
            ]
          : []
      }
      onBack={showItems ? () => setShowItems(false) : undefined}
      textSave={!showItems ? t('buttons.nextStep') ?? '' : undefined}
    >
      <ItemDrawer
        useDrawerForm={createItemDrawer}
        setResourceCreate={setResourceCreateItem}
        breadcrumbList={[t('invoice.form.breadcrumb') ?? '']}
        isStockItem={false}
      />
      <SupplierCreateContainer
        supplierDrawerForm={createSupplierDrawer}
        breadcrumbList={[t('invoice.form.breadcrumb') ?? '']}
      />
      <Styled.BoxButtons data-testid="stock-trasnfer-buttons-wrapper">
        <Styled.ButtonSelected
          onClick={() => setShowItems(false)}
          type={detailTabClassname}
        >
          {t('invoice.form.details.title')}
        </Styled.ButtonSelected>
        <Styled.ButtonSelected
          onClick={() => setShowItems(true)}
          type={itemsTabClassname}
          disabled={disableItemsTab}
        >
          {t('invoice.form.items.title')}
          <Styled.BoxAmount isSelected={showItems}>
            {itemsAmount}
          </Styled.BoxAmount>
        </Styled.ButtonSelected>
      </Styled.BoxButtons>
      <Form
        layout="vertical"
        {...formProps}
        onFinish={handleOnFinish}
        data-testid="item-category-form"
        initialValues={initialValues}
        disabled={isEdit}
      >
        <InvoiceFormDetails
          handleOpenSupplierDrawer={handleOpenSupplierDrawer}
          isEdit={isEdit}
        />
        <InvoiceFormItems handleOpenItemDrawer={handleOpenItemDrawer} />
      </Form>
      {isCancelled && (
        <CancelledBy
          cancelledBy={formProps.initialValues?.updatedByUserName}
          cancelledAt={formProps.initialValues?.updatedAt}
        />
      )}
    </DrawerWithButtons>
  );
};
