import Icon from '@ant-design/icons';
import { ConfigProvider, Popconfirm, Space, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import { EmptyState, FormattedDate } from 'common/components';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { DeleteIcon } from 'common/icons';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { GetComponentProps } from 'rc-table/lib/interface';
import { useTranslation } from 'react-i18next';

import AmountColumn from './amount-column/amount-column.component';
import useInvoiceReceiptTable from './invoice-receipt-table.hook';
import { InvoiceReceiptTableModel } from './invoice-receipt-table.model';
import * as Styled from './invoice-receipt-table.style';
import StatusColumn from './status-column/status-column.component';

interface PurchaseDetailsTableProps {
  purchaseId: string;
}

const InvoiceReceiptTable = ({ purchaseId }: PurchaseDetailsTableProps) => {
  const { t } = useTranslation();
  const { permissions } = usePermissionsBalance();
  const {
    invoiceReceiptData,
    filterItems,
    isLoading,
    filterItemsStatus,
    onFilterStatus,
    handleOnClickDelete,
  } = useInvoiceReceiptTable(purchaseId);

  const hasReadPermission = permissions?.includes(
    ActionRbacEnum.FINANCIAL_BALANCE_READ,
  );
  const hasWritePermission = permissions?.includes(
    ActionRbacEnum.FINANCIAL_BALANCE_WRITE,
  );

  let invoiceReceiptDataOriginalIds = invoiceReceiptData
    .sort((a, b) => b.date.diff(a.date))
    .map((data) => data.originalId);

  const shouldRenderColumn = (originalId: string, index: number) => {
    return (
      index === 0 || originalId !== invoiceReceiptDataOriginalIds[index - 1]
    );
  };

  const onCell: GetComponentProps<InvoiceReceiptTableModel> = (
    record,
    index,
  ) => {
    return record.originalId ===
      invoiceReceiptDataOriginalIds[(index as number) + 1]
      ? {
          style: {
            border: 'none',
          },
        }
      : {};
  };

  return (
    <ConfigProvider
      renderEmpty={() => (
        <EmptyState
          title={t('purchase.invoice_receipt.empty_state.title')}
          description={`${t(
            'purchase.invoice_receipt.empty_state.description',
          )}`}
          marginTop="0"
        />
      )}
    >
      <Styled.Table
        dataSource={invoiceReceiptData}
        rowKey="id"
        pagination={false}
        loading={
          isLoading && {
            spinning: true,
            tip: t('purchase.invoice_receipt.table.loading'),
          }
        }
        onChange={(_1, _2, _3, extra) => {
          invoiceReceiptDataOriginalIds = extra.currentDataSource.map(
            (data) => data.originalId,
          );
        }}
      >
        <Column
          title={t('purchase.invoice_receipt.table.date')}
          render={({ date, originalId }, _, index) => {
            return shouldRenderColumn(originalId, index) ? (
              <FormattedDate date={date} />
            ) : null;
          }}
          sorter={(a: InvoiceReceiptTableModel, b: InvoiceReceiptTableModel) =>
            a.date.diff(b.date)
          }
          onCell={onCell}
        />
        <Column
          title={t('purchase.invoice_receipt.table.status')}
          filters={filterItemsStatus}
          onFilter={onFilterStatus}
          render={(data, _, index) => {
            return shouldRenderColumn(data.originalId, index) ? (
              <StatusColumn data={data} />
            ) : null;
          }}
          width={150}
          onCell={onCell}
        />
        <Column
          title={t('purchase.invoice_receipt.table.id')}
          render={({ viewId, originalId }, _, index) => {
            return shouldRenderColumn(originalId, index) ? viewId : null;
          }}
          onCell={onCell}
          width={150}
        />
        <Column
          title={t('purchase.invoice_receipt.table.description')}
          filters={filterItems}
          onFilter={(value, record: InvoiceReceiptTableModel) =>
            record.itemCodes.has(value as string)
          }
          render={({ itemName }) => (
            <Typography.Paragraph
              ellipsis={{ tooltip: { title: itemName }, rows: 1 }}
              style={{
                width: 150,
                marginBottom: 0,
              }}
            >
              {itemName}
            </Typography.Paragraph>
          )}
          width={200}
        />
        <Column
          title={t('purchase.invoice_receipt.table.amount')}
          render={(_, data: InvoiceReceiptTableModel) => (
            <AmountColumn data={data} />
          )}
        />
        {hasReadPermission && hasWritePermission && (
          <Column
            title={t('purchase.invoice_receipt.table.actions')}
            render={(_, data: InvoiceReceiptTableModel, index) => {
              return (
                shouldRenderColumn(data.originalId, index) && (
                  <Space>
                    <Popconfirm
                      title={t(
                        `purchase.invoice_receipt.table.delete_${data.type}_title`,
                      )}
                      description={t(
                        `purchase.invoice_receipt.table.delete_${data.type}_description`,
                      )}
                      onConfirm={() =>
                        handleOnClickDelete(data.originalId, data.type)
                      }
                      okText={t('buttons.yes')}
                      cancelText={t('buttons.no')}
                    >
                      <Styled.CustomDeleteButton
                        disabled={data.isCancelled}
                        danger
                        icon={<Icon component={DeleteIcon} />}
                      />
                    </Popconfirm>
                  </Space>
                )
              );
            }}
          />
        )}
      </Styled.Table>
    </ConfigProvider>
  );
};

export { InvoiceReceiptTable as default, InvoiceReceiptTable };
