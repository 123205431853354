import { FormListFieldData, InputNumber, Select, SelectProps } from 'antd';
import { InputMoney } from 'common/components';
import { getCurrencySymbol } from 'common/utils';
import { getCurrencyValue } from 'common/utils/currency.util';
import { useTranslation } from 'react-i18next';
import { hasMoreThanDecimalPlaces } from 'src/orders-and-payments/purchase/purchase.util';
import { ItemDetailPurchaseModel } from 'src/resources/item';

import { PurchaseCreateItemTestId } from '../../../../purchase-test-id.constants';
import { usePurchaseCreateStore } from '../../../purchase-create.store';
import { usePurchaseCreateItemDetails } from './purchase-create-item-details.hook';
import * as Styled from './purchase-create-item-details.style';

interface PurchaseCreateItemDetailsProps {
  itemFieldData: FormListFieldData;
  item: ItemDetailPurchaseModel;
  receivedQty: number;
  warehouseSelectProps: SelectProps;
  itemWarehouse: string;
  isReadonly: boolean;
  isOpen: boolean;
  itemQty?: number;
}

export const PurchaseCreateItemDetails = ({
  itemFieldData,
  item,
  receivedQty,
  itemQty,
  warehouseSelectProps,
  isReadonly = false,
  isOpen,
}: PurchaseCreateItemDetailsProps) => {
  const { t, i18n } = useTranslation();
  const { currency, customCurrency } = usePurchaseCreateStore();
  const {
    totalValue,
    rate,
    hasMoreDecimalPlaces,
    newTotalValue,
    handleOnChangeQty,
    handleOnChangeRate,
    handleOnChangeTotalValue,
    handleUpdateTotalValue,
    isOtherCurrency,
    exchangeRate,
  } = usePurchaseCreateItemDetails(itemFieldData?.name);

  const getAddonBefore = () => {
    if (isOtherCurrency && customCurrency?.isMonetary) {
      return customCurrency?.symbol;
    }
  };

  const getAddonAfter = () => {
    if (isOtherCurrency && !customCurrency?.isMonetary) {
      return customCurrency?.symbol;
    } else if (!isOtherCurrency) {
      return getCurrencySymbol(currency);
    }
  };

  return (
    <>
      {isReadonly && (
        <Styled.BoxReceived>
          <div
            data-testid={
              PurchaseCreateItemTestId.PURCHASE_CREATE_ITEMS_RECEIVED
            }
          >
            <div>
              {t('purchase_create.items.received', {
                receivedQty: `${receivedQty} ${item.stockUom}`,
                qty: `${itemQty ?? item?.qty} ${item.stockUom}`,
              })}
            </div>
          </div>
        </Styled.BoxReceived>
      )}
      <Styled.FormItem
        label={t('purchase_create.items.amount')}
        name={[itemFieldData?.name, 'qty']}
        data-testid={PurchaseCreateItemTestId.PURCHASE_CREATE_ITEM_QTY}
        rules={[
          {
            required: true,
          },
        ]}
        hidden={!isOpen}
      >
        <InputNumber
          style={{ width: '100%' }}
          onChange={handleOnChangeQty}
          addonAfter={item?.stockUom}
          decimalSeparator={','}
          precision={2}
        />
      </Styled.FormItem>
      <Styled.ContainerValues>
        <Styled.FormItemValue
          label={t('purchase_create.items.unit_value')}
          name={[itemFieldData?.name, 'rate']}
          data-testid={PurchaseCreateItemTestId.PURCHASE_CREATE_ITEM_RATE}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (
                  Number(value) <= 0 ||
                  hasMoreThanDecimalPlaces(totalValue, newTotalValue)
                ) {
                  return Promise.reject(
                    new Error(t('common.business.unit_value_error') ?? ''),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          hidden={!isOpen}
          extra={
            isOtherCurrency &&
            getCurrencyValue(rate * exchangeRate, currency, i18n.language)
          }
        >
          <InputMoney
            addonBefore={getAddonBefore()}
            onChange={handleOnChangeRate}
            maxLength={12}
            addonAfter={getAddonAfter()}
          />
        </Styled.FormItemValue>
        <Styled.FormItemValue
          label={t('purchase_create.items.total_value')}
          name={[itemFieldData?.name, 'totalValue']}
          hidden={!isOpen}
          extra={
            isOtherCurrency &&
            getCurrencyValue(totalValue * exchangeRate, currency, i18n.language)
          }
        >
          <InputMoney
            onChange={handleOnChangeTotalValue}
            addonBefore={getAddonBefore()}
            addonAfter={getAddonAfter()}
          />
        </Styled.FormItemValue>
      </Styled.ContainerValues>
      {hasMoreDecimalPlaces && (
        <Styled.AlertError
          message={t('purchase_create.items.alert_title', {
            value: newTotalValue?.toLocaleString(i18n.language, {
              style: 'currency',
              currency,
            }),
          })}
          description={
            <Styled.AlertContainer>
              <Styled.DescriptionText>
                {t('purchase_create.items.alert_description')}
              </Styled.DescriptionText>
              <Styled.UpdateButton onClick={handleUpdateTotalValue}>
                {t('common.update')}
              </Styled.UpdateButton>
            </Styled.AlertContainer>
          }
          showIcon
          icon={<Styled.InfoCircleIcon />}
          type="error"
        />
      )}
      <Styled.FormItem
        label={t('purchase_create.items.warehouse')}
        name={[itemFieldData?.name, 'warehouse']}
        rules={[
          {
            required: true,
          },
        ]}
        hidden={!isOpen}
      >
        <Select
          {...warehouseSelectProps}
          defaultValue={item?.warehouse}
          data-testid={PurchaseCreateItemTestId.PURCHASE_CREATE_ITEM_WAREHOUSE}
        />
      </Styled.FormItem>
    </>
  );
};
