import { UseDrawerFormReturnType } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { Form, Grid, Skeleton } from 'antd';
import { DrawerWithButtons } from 'common/components/drawer-with-buttons/drawer-with-buttons.component';
import { useTranslation } from 'react-i18next';
import { BankAccountCreateContainer } from 'src/resources/bank-account/bank-account-form/bank-account-form.container';

import { PurchaseDetailModel } from '../purchase.model';
import { PurchasePaymentTestId } from './purchase-payment.constants';
import { usePurchasePayment } from './purchase-payment.hook';
import { PurchasePaymentRequestModel } from './purchase-payment.model';
import PurchasePaymentDetail from './purchase-payment-detail/purchase-payment-detail.component';
import { PurchasePaymentInfo } from './purchase-payment-info/purchase-payment-info.container';
import { SuccessOtherCurrency } from './success-other-currency/success-other-currency.component';

interface PurchasePaymentProps {
  drawerForm: UseDrawerFormReturnType<
    PurchaseDetailModel,
    HttpError,
    PurchasePaymentRequestModel
  >;
}

const PurchasePayment = ({ drawerForm }: PurchasePaymentProps) => {
  const {
    close: onClose,
    form: formInstance,
    drawerProps,
    formProps,
    saveButtonProps: onCreate,
    queryResult: paymentQueryResult,
  } = drawerForm ?? {};
  const {
    showSuccessOtherCurrency,
    purchaseOrder,
    initialValues,
    isDisabledSave,
    isLoading,
    handleOnFinish,
    createBankAccountDrawer,
    handleOpenBankAccountDrawer,
    handleOnClose,
  } = usePurchasePayment(formProps, formInstance, onClose);

  const isStartLoading = !!paymentQueryResult?.isFetching;

  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();

  const renderDrawerChildren = () => {
    if (isStartLoading) {
      return <Skeleton active data-testid={PurchasePaymentTestId.SKELETON} />;
    }

    return (
      <>
        <PurchasePaymentInfo
          handleOpenBankAccountDrawer={handleOpenBankAccountDrawer}
        />
        <PurchasePaymentDetail purchaseOrder={purchaseOrder} />
      </>
    );
  };

  return (
    <DrawerWithButtons
      {...drawerProps}
      onClose={handleOnClose}
      width={breakpoint.sm ? '400px' : '100%'}
      zIndex={1001}
      title={t('purchase_payment.title')}
      disabledSave={isDisabledSave}
      isLoading={isLoading}
      tagTrackClose="Purchase Payment"
      buttonSaveProps={
        showSuccessOtherCurrency ? { onClick: handleOnClose } : onCreate
      }
      data-testid={PurchasePaymentTestId.Container}
      textSave={showSuccessOtherCurrency ? t('buttons.close') ?? '' : undefined}
      backButton={showSuccessOtherCurrency ? <div /> : undefined}
    >
      {showSuccessOtherCurrency ? (
        <SuccessOtherCurrency purchaseOrderId={purchaseOrder?.id} />
      ) : (
        <>
          <BankAccountCreateContainer
            bankAccountDrawerForm={createBankAccountDrawer}
            breadcrumbList={[t('purchase_create.breadcrumb')]}
          />
          <Form
            layout="vertical"
            {...formProps}
            onFinish={handleOnFinish}
            initialValues={initialValues}
          >
            {renderDrawerChildren()}
          </Form>
        </>
      )}
    </DrawerWithButtons>
  );
};

export { PurchasePayment as default, PurchasePayment };
