import { Form } from 'antd';
import { CalendarIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import { PurchaseReceiveDateTestId } from '../purchase-receive.constants';
import * as Styled from './purchase-receive-date.style';

const DATE_FORMAT = 'DD/MM/YYYY';

export const PurchaseReceiveDate = () => {
  const { t } = useTranslation();

  return (
    <Styled.Space
      size={4}
      data-testid={PurchaseReceiveDateTestId.PURCHASE_RECEIVE_DATE_CONTAINER}
    >
      <Styled.IconWrapper>
        <CalendarIcon />
      </Styled.IconWrapper>
      {t('purchase_receive.date.received_on')}
      <Styled.FormItem
        name={['postingDate']}
        rules={[{ required: true }]}
        data-testid={
          PurchaseReceiveDateTestId.PURCHASE_RECEIVE_DATE_DATE_PICKER
        }
      >
        <Styled.DatePicker format={DATE_FORMAT} suffixIcon />
      </Styled.FormItem>
      {t('purchase_receive.date.at')}
      <Styled.FormItem
        name={['postingTime']}
        rules={[{ required: true }]}
        data-testid={
          PurchaseReceiveDateTestId.PURCHASE_RECEIVE_DATE_TIME_PICKER
        }
      >
        <Styled.TimePicker suffixIcon />
      </Styled.FormItem>
      <Form.Item name={['farm']} hidden />
      <Form.Item name={['supplier']} hidden />
      <Form.Item name={['currency']} hidden />
      <Form.Item name={['isOtherCurrency']} hidden />
      <Form.Item name={['typeOtherCurrency']} hidden />
    </Styled.Space>
  );
};
