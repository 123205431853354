import { useOne } from '@refinedev/core';
import { Divider } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { ClockIcon, DollarIcon, PurchaseIcon } from 'common/icons';
import { getCurrencySymbol } from 'common/utils';
import {
  getCurrencyValue,
  getValueWithCurrency,
} from 'common/utils/currency.util';
import { useTranslation } from 'react-i18next';
import { useFarm } from 'src/organization';

import { ContainerIconValue } from '../../container-icon-value/container-icon-value.component';
import { OtherCurrencyModel, PurchaseFormModel } from '../../purchase.model';
import { PurchasePaymentDetailTestId } from '../purchase-payment.constants';
import * as Styled from './purchase-payment-detail.style';

interface PurchasePaymentDetailProps {
  purchaseOrder?: PurchaseFormModel;
}

const PurchasePaymentDetail = ({
  purchaseOrder,
}: PurchasePaymentDetailProps) => {
  const { t, i18n } = useTranslation();
  const { getCurrencyByFarm } = useFarm();
  const farm = useWatch(['farm']);

  const { data } = useOne<OtherCurrencyModel>({
    resource: 'other-currency',
    id: purchaseOrder?.typeOtherCurrency,
    queryOptions: {
      enabled: !!purchaseOrder?.typeOtherCurrency,
    },
  });

  const currency = getCurrencyByFarm(farm);

  return (
    <Styled.Container
      data-testid={
        PurchasePaymentDetailTestId.PURCHASE_PAYMENT_DETAIL_CONTAINER
      }
    >
      {purchaseOrder?.isOtherCurrency && data?.data && (
        <ContainerIconValue
          Icon={<PurchaseIcon />}
          title={t('purchase_create.payed.total_traded')}
          value={getValueWithCurrency(
            purchaseOrder?.valueOtherCurrency ?? 0,
            data.data.symbol,
            i18n.language,
          )}
          isBlue={false}
        />
      )}
      <Styled.AmountWrapper>
        <ContainerIconValue
          Icon={<ClockIcon />}
          title={t('purchase_payment.outstanding_total')}
          value={
            purchaseOrder?.isOtherCurrency && data?.data
              ? getValueWithCurrency(
                  (purchaseOrder?.valueOtherCurrency ?? 0) -
                    (purchaseOrder?.paidAmountOtherCurrency ?? 0),
                  data?.data.symbol,
                  i18n.language,
                )
              : getCurrencyValue(
                  (purchaseOrder?.grandTotal ?? 0) -
                    (purchaseOrder?.paidAmount ?? 0),
                  purchaseOrder?.currency,
                  i18n.language,
                )
          }
          complement={
            purchaseOrder?.isOtherCurrency
              ? getCurrencyValue(
                  (purchaseOrder?.grandTotalWithDiff ?? 0) -
                    (purchaseOrder?.paidAmount ?? 0),
                  purchaseOrder?.currency,
                  i18n.language,
                )
              : undefined
          }
          isBlue={false}
        />
        <ContainerIconValue
          Icon={<DollarIcon />}
          title={t('purchase_payment.paid_total')}
          value={
            purchaseOrder?.isOtherCurrency && data?.data
              ? getValueWithCurrency(
                  purchaseOrder?.paidAmountOtherCurrency ?? 0,
                  data?.data.symbol,
                  i18n.language,
                )
              : getCurrencyValue(
                  purchaseOrder?.paidAmount ?? 0,
                  purchaseOrder?.currency,
                  i18n.language,
                )
          }
          complement={
            purchaseOrder?.isOtherCurrency
              ? getCurrencyValue(
                  purchaseOrder?.paidAmount ?? 0,
                  purchaseOrder?.currency,
                  i18n.language,
                )
              : undefined
          }
          isBlue={false}
        />
      </Styled.AmountWrapper>
      <Divider style={{ margin: 0 }} />
      {purchaseOrder?.isOtherCurrency ? (
        <Styled.ContainerFlexBetween>
          <Styled.FormItem
            name={['exchangeRateOtherCurrency']}
            label={t('purchase_payment.final_quote')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Styled.InputNumber
              prefix={getCurrencySymbol(currency)}
              addonAfter={`= ${data?.data.symbol} 1,00`}
            />
          </Styled.FormItem>
          <Styled.FormItem
            name={['valueOtherCurrency']}
            label={t('purchase_payment.paid_amount')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Styled.InputNumber prefix={data?.data.symbol} />
          </Styled.FormItem>
        </Styled.ContainerFlexBetween>
      ) : (
        <Styled.FormItem
          name={['amount']}
          label={t('purchase_payment.paid_amount')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Styled.InputNumber
            prefix={getCurrencySymbol(currency)}
            data-testid={
              PurchasePaymentDetailTestId.PURCHASE_PAYMENT_DETAIL_PAID_AMOUNT
            }
          />
        </Styled.FormItem>
      )}
    </Styled.Container>
  );
};

export { PurchasePaymentDetail as default, PurchasePaymentDetail };
