import { DatePicker, FormProps, Input, Select, Switch } from 'antd';
import { AutoSingleSelect, SelectWithButton } from 'common/components';
import { CancelledBy } from 'common/components/cancelled-by/cancelled-by.component';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { StatusEnum } from 'src/orders-and-payments/invoice/status/invoice-status.contant';
import EntitlementEnum from 'src/organization/entitlements.enum';

import { PurchaseRequestModel } from '../../purchase.model';
import { usePurchaseCreateStore } from '../purchase-create.store';
import OtherCurrencies from './other-currencies/other-currencies.component';
import { usePurchaseCreateDetails } from './purchase-create-details.hook';
import * as Styled from './purchase-create-details.style';

interface PurchaseCreateDetailsProps {
  formProps: FormProps<PurchaseRequestModel>;
  isReadonly?: boolean;
  handleOpenSupplierDrawer: () => void;
  handleOpenBankAccountDrawer: () => void;
}
export const PurchaseCreateDetails = ({
  formProps,
  isReadonly,
  handleOpenSupplierDrawer,
  handleOpenBankAccountDrawer,
}: PurchaseCreateDetailsProps) => {
  const { t } = useTranslation();
  const { purchaseFms1488OtherCurrency } = useFlags();
  const {
    entitlements,
    farmName,
    scheduleDate,
    farmSelectProps,
    supplierSelectProps,
    costCenterSelectProps,
    isPaid,
    accountSelectProps,
    hasCustomAccount,
  } = usePurchaseCreateDetails();

  const { showItems } = usePurchaseCreateStore();

  return (
    <>
      <Styled.Box isShow={!showItems}>
        <Styled.ContainerDates>
          <Styled.FormItem
            label={t('purchase_create.details.date')}
            name={['transactionDate']}
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (value > scheduleDate) {
                    return Promise.reject(
                      new Error(t('purchase_create.details.date_error') ?? ''),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker format={'DD/MM/YYYY'} />
          </Styled.FormItem>
          <Styled.FormItem
            label={t('purchase_create.details.required_by')}
            name={['scheduleDate']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker format={'DD/MM/YYYY'} />
          </Styled.FormItem>
        </Styled.ContainerDates>
        <Styled.FormItem
          label={t('purchase_create.details.farm')}
          name={['farmName']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AutoSingleSelect
            {...farmSelectProps}
            placeholder={t('common.business.farm_placeholder')}
          />
        </Styled.FormItem>
        <Styled.ContainerDates>
          <Styled.FormItem
            label={t('purchase_create.details.purchase_number')}
            name={['purchaseNumber']}
          >
            <Input />
          </Styled.FormItem>
          <Styled.FormItem
            style={{ maxWidth: '50%' }}
            label={t('purchase_create.details.supplier')}
            name={['supplier']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectWithButton
              {...supplierSelectProps}
              placeholder={t('common.business.supplier_placeholder')}
              buttonProps={{
                onClick: handleOpenSupplierDrawer,
                children: t('common.create_new'),
              }}
            />
          </Styled.FormItem>
        </Styled.ContainerDates>
        {farmName && (
          <Styled.FormItem
            label={t('purchase_create.details.cost_center')}
            name={['costCenter']}
          >
            <Select
              allowClear
              {...costCenterSelectProps}
              placeholder={t('common.business.cost_center_placeholder')}
            />
          </Styled.FormItem>
        )}
      </Styled.Box>
      {purchaseFms1488OtherCurrency &&
        entitlements.includes(
          EntitlementEnum.PURCHASE_ORDER_IN_OTHER_CURRENCIES,
        ) && <OtherCurrencies isShow={!showItems && !isReadonly} />}
      {entitlements.includes(EntitlementEnum.INVOICES) && (
        <Styled.PaymentBox isShow={!showItems && !isReadonly} isOpen={isPaid}>
          <Styled.SwitchBox>
            <span>{t('purchase_create.details.is_paid')}</span>
            <Styled.SwitchForm name={['isPaid']} valuePropName={'checked'}>
              <Switch
                datatest-id="is-paid-switchbox"
                checkedChildren={t('buttons.yes')}
                unCheckedChildren={t('buttons.no')}
              />
            </Styled.SwitchForm>
          </Styled.SwitchBox>
          <Styled.Divider />
          <Styled.PaymentInputWrapper>
            <Styled.FormItem
              label={t(
                `purchase_create.details.${
                  isPaid ? 'payment_date' : 'due_date'
                }`,
              )}
              name={['dueDate']}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={'DD/MM/YYYY'} />
            </Styled.FormItem>
            {isPaid && (
              <>
                <Styled.FormItem
                  label={t(
                    `purchase_create.details.${
                      hasCustomAccount ? 'bank_account' : 'payment_method'
                    }`,
                  )}
                  name={['paymentAccount']}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  extra={
                    !farmName &&
                    t(
                      `purchase_create.details.${
                        hasCustomAccount ? 'account' : 'payment_method'
                      }_extra`,
                    )
                  }
                >
                  <SelectWithButton
                    {...accountSelectProps}
                    buttonProps={{
                      onClick: handleOpenBankAccountDrawer,
                      children: t('common.create_new'),
                    }}
                    disabled={!farmName}
                  />
                </Styled.FormItem>
                <Styled.FormItem name={['paymentAccountType']} hidden />
              </>
            )}
          </Styled.PaymentInputWrapper>
        </Styled.PaymentBox>
      )}
      {formProps.initialValues?.status === StatusEnum.Cancelled &&
        !!formProps.initialValues?.updatedAt &&
        !!formProps.initialValues?.updatedByUserName && (
          <CancelledBy
            cancelledBy={formProps.initialValues?.updatedByUserName}
            cancelledAt={formProps.initialValues?.updatedAt}
          />
        )}
    </>
  );
};
