import { Skeleton, Space } from 'antd';
import { CurrencyValue } from 'common/components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OtherCurrencyModel } from '../../purchase.model';

interface TotalColumnProps {
  currency: string;
  isOtherCurrency: boolean;
  total: number;
  otherCurrencyTotal: number;
  otherCurrency?: OtherCurrencyModel;
}

const TotalColumn = ({
  currency,
  isOtherCurrency,
  total,
  otherCurrencyTotal,
  otherCurrency,
}: TotalColumnProps) => {
  const { i18n } = useTranslation();

  const handleOtherCurrencyValue = useCallback(
    (
      otherCurrency: OtherCurrencyModel | undefined,
      otherCurrencyTotal: number,
    ) => {
      if (!otherCurrency) {
        return (
          <Space>
            <Skeleton.Avatar shape="square" size="small" active />
            {otherCurrencyTotal.toLocaleString(i18n.language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Space>
        );
      }

      if (otherCurrency?.isMonetary) {
        return `${otherCurrency.symbol} ${otherCurrencyTotal.toLocaleString(
          i18n.language,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        )}`;
      }
      return `${otherCurrencyTotal.toLocaleString(i18n.language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} ${otherCurrency?.symbol}`;
    },
    [i18n.language],
  );

  return isOtherCurrency ? (
    <>{handleOtherCurrencyValue(otherCurrency, otherCurrencyTotal)}</>
  ) : (
    <CurrencyValue currency={currency} value={total} />
  );
};

export { TotalColumn as default, TotalColumn };
