import { create } from 'zustand';

import { OtherCurrencyModel } from '../purchase.model';

type PurchaseCreateState = {
  showItems: boolean;
  currency: string;
  customCurrency: OtherCurrencyModel | null;
};

type PurchaseCreateActions = {
  setShowItems: (value: boolean) => void;
  setCurrency: (value: string) => void;
  setCustomCurrency: (value: OtherCurrencyModel) => void;
};

const usePurchaseCreateStore = create<
  PurchaseCreateState & PurchaseCreateActions
>((set) => ({
  showItems: false,
  setShowItems: (value: boolean) => set(() => ({ showItems: value })),
  currency: 'BRL',
  setCurrency: (value: string) => set(() => ({ currency: value })),
  customCurrency: null,
  setCustomCurrency: (value: OtherCurrencyModel) =>
    set(() => ({ customCurrency: value })),
}));

export { usePurchaseCreateStore };
