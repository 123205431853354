import { Form, Space as SpaceAntd } from 'antd';
import { InputMoney } from 'common/components';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Container = styled.div`
  padding: 16px;
  border: 1px solid ${NeutralColors[20]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContainerFlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 16px;
`;

const FormItem = styled(Form.Item)``;

const AmountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AmountValue = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
`;

const IconWrapper = styled.span`
  width: 22px;
  height: 22px;
  border-radius: 4px;
  padding: 2px;
  background-color: ${NeutralColors[10]};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Space = styled(SpaceAntd)`
  .fms-space-item {
    display: inline-flex;
  }
`;

const InputNumber = styled(InputMoney)`
  width: 100%;
`;
export {
  AmountValue,
  AmountWrapper,
  Container,
  ContainerFlexBetween,
  FormItem,
  IconWrapper,
  InputNumber,
  Space,
};
