import { useOne } from '@refinedev/core';
import { FormListFieldData } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { WarehouseModel } from 'src/stock/warehouse-list';

const usePurchaseReceiveItem = (itemFieldData: FormListFieldData) => {
  const formInstance = useFormInstance();
  const itemName = useWatch(['items', itemFieldData.name, 'name']);
  const stockUom = useWatch(['items', itemFieldData.name, 'stockUom']);
  const warehouse = useWatch(['items', itemFieldData.name, 'warehouse']);
  const outstadingQty = useWatch([
    'items',
    itemFieldData.name,
    'outstandingQty',
  ]);
  const totalQty = useWatch(['items', itemFieldData.name, 'totalQty']);
  const currency = useWatch(['currency']);
  const isHidden = useWatch(['items', itemFieldData.name, 'isHidden']);
  const lockedQty = useWatch(['items', itemFieldData.name, 'lockedQty']);
  const isOtherCurrency = useWatch(['isOtherCurrency']);

  const { data, isFetching } = useOne<WarehouseModel>({
    id: warehouse,
    resource: 'warehouse',
  });

  const handleHideItem = () => {
    formInstance.setFieldValue(['items', itemFieldData.name, 'isHidden'], true);
  };

  return {
    itemName,
    stockUom,
    warehouseName: data?.data.name,
    warehouseIsLoading: isFetching,
    currency,
    outstadingQty,
    totalQty,
    lockedQty,
    isHidden,
    isOtherCurrency,
    handleHideItem,
  };
};

export default usePurchaseReceiveItem;
