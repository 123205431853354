import { useDrawerForm } from '@refinedev/antd';
import { HttpError, useDelete } from '@refinedev/core';
import { DrawerProps, FormInstance, FormProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useRedirect } from 'common/hooks';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useOrganization } from 'src/organization';
import EntitlementEnum from 'src/organization/entitlements.enum';
import { useBankAccountCreateDrawer } from 'src/resources/bank-account/use-bank-account-create-drawer.hook';
import { useCreateItemDrawer } from 'src/resources/item/use-item-create-drawer.hook';
import { useSupplierCreateDrawer } from 'src/resources/supplier/use-supplier-create-drawer.hook';

import {
  PurchaseDetailModel,
  PurchaseFormModel,
  PurchaseItemFormModel,
  PurchaseItemRequestModel,
  PurchaseRequestModel,
} from '../purchase.model';
import { usePurchaseCreateStore } from './purchase-create.store';

const usePurchaseDrawer = <T>(action: 'edit' | 'create', key?: string) => {
  const { currentOrganizationId } = useOrganization();

  const purchaseDrawer = useDrawerForm<PurchaseDetailModel, HttpError, T>({
    action,
    resource: 'purchase-order',
    redirect: false,
    meta: {
      organizationId: currentOrganizationId,
    },
    syncWithLocation: key
      ? {
          key,
        }
      : undefined,
  });

  return {
    purchaseDrawer,
  };
};

const usePurchaseCreate = (
  formInstance: FormInstance,
  formProps: FormProps,
  drawerProps: DrawerProps,
) => {
  const { entitlements } = useOrganization();
  const { initialValuesState } = useRedirect<PurchaseRequestModel>();
  const { track } = useTracking();
  const { mutate: mutateDelete } = useDelete();
  const { currency, setShowItems } = usePurchaseCreateStore();

  const formValues = useWatch([], formInstance);

  useEffect(() => {
    if (!drawerProps.open) {
      setShowItems(false);
      formInstance.resetFields();
    }
  }, [drawerProps.open, formInstance.resetFields, setShowItems]);

  const initialValues: PurchaseFormModel = useMemo(() => {
    if (initialValuesState) {
      return {
        ...initialValuesState,
        id: formProps.initialValues?.id as string,
        transactionDate: dayjs(initialValuesState.transactionDate),
        scheduleDate: dayjs(initialValuesState.scheduleDate),
        dueDate: dayjs(initialValuesState.dueDate),
        farmName: initialValuesState.farmName,
        supplier: initialValuesState.supplier,
        costCenter: initialValuesState.costCenter as string,
        status: initialValuesState.status,
        purchaseNumber: initialValuesState.purchaseNumber,
        isPaid: initialValuesState.isPaid,
        isReceived: initialValuesState.isReceived,
        isOtherCurrency: initialValuesState.isOtherCurrency,
        typeOtherCurrency: initialValuesState.typeOtherCurrency,
        initialExchangeRateOtherCurrency:
          initialValuesState.initialExchangeRateOtherCurrency ?? 5,
        items: initialValuesState.items?.map(
          (item: PurchaseItemRequestModel) => ({
            itemCode: item?.itemCode,
            qty: item?.qty,
            rate: item?.rate,
            receivedQty: item?.receivedQty,
            warehouse: item?.warehouse,
            totalValue: item.amount,
          }),
        ),
      } as PurchaseFormModel;
    }
    return {
      ...(formProps?.initialValues ? formProps.initialValues : {}),
      id: formProps.initialValues?.id as string,
      transactionDate:
        dayjs(formProps.initialValues?.transactionDate) ?? dayjs(),
      scheduleDate: dayjs(formProps.initialValues?.scheduleDate) ?? dayjs(),
      farmName: formProps.initialValues?.farmName as string,
      purchaseNumber: formProps.initialValues?.purchaseNumber as string,
      supplier: formProps.initialValues?.supplier as string,
      costCenter: formProps.initialValues?.costCenter as string,
      status: formProps.initialValues?.status as string,
      dueDate: dayjs(formProps.initialValues?.dueDate) ?? dayjs(),
      typeOtherCurrency: formProps.initialValues?.typeOtherCurrency ?? 'Dolar',
      items: formProps.initialValues?.items?.map(
        (item: PurchaseItemRequestModel) => ({
          itemCode: item.itemCode,
          qty: item.qty,
          rate: formProps.initialValues?.isOtherCurrency
            ? item.rate /
              (formProps.initialValues?.initialExchangeRateOtherCurrency ?? 1)
            : item.rate,
          warehouse: item.warehouse,
          receivedQty: item?.receivedQty,
          totalValue: formProps.initialValues?.isOtherCurrency
            ? item.amount /
              (formProps.initialValues?.initialExchangeRateOtherCurrency ?? 1)
            : item.amount,
        }),
      ),
    };
  }, [formProps.initialValues, initialValuesState]);

  const disableItemsTab = useMemo(
    () =>
      !formValues?.scheduleDate ||
      !formValues?.transactionDate ||
      !formValues?.farmName ||
      !formValues?.supplier ||
      (!formValues?.dueDate &&
        entitlements?.includes(EntitlementEnum.INVOICES)) ||
      (formValues?.isOtherCurrency &&
        !formValues?.initialExchangeRateOtherCurrency),
    [
      formValues?.farmName,
      formValues?.scheduleDate,
      formValues?.supplier,
      formValues?.transactionDate,
      formValues?.dueDate,
      formValues?.isOtherCurrency,
      formValues?.initialExchangeRateOtherCurrency,
      entitlements,
    ],
  );

  const calculatedGrandTotal = useMemo(() => {
    return formValues?.items?.reduce(
      (acc: number, item: PurchaseItemFormModel) => {
        if (item?.qty === undefined || item?.rate === undefined) {
          return acc;
        }
        return acc + item.qty * (item.rate ?? 0);
      },
      0,
    );
  }, [formValues?.items]);

  const handleOnFinish = (values: PurchaseRequestModel) => {
    formInstance
      .validateFields()
      .then(() => {
        track('End Purchase', {
          isOtherCurrency: !!values.isOtherCurrency,
        });
        if (formProps.onFinish) {
          const data = {
            ...values,
            currency,
            transactionDate: dayjs(values.transactionDate).format('YYYY-MM-DD'),
            scheduleDate: dayjs(values.scheduleDate).format('YYYY-MM-DD'),
            dueDate: dayjs(values.dueDate).format('YYYY-MM-DD'),
            items: values.items?.map((item) => ({
              ...item,
            })),
          };

          if (values.isOtherCurrency) {
            data.typeOtherCurrency = values.typeOtherCurrency;
            data.initialExchangeRateOtherCurrency =
              values.initialExchangeRateOtherCurrency;
            data.isOtherCurrency = values.isOtherCurrency;
            data.valueOtherCurrency = values.items.reduce(
              (acc, item) => acc + item.qty * (item.rate ?? 0),
              0,
            );
            data.items = values.items.map((item) => ({
              // current values are in other currency so we need to convert them to the main currency
              ...item,
              typeOtherCurrency: values.typeOtherCurrency,
              valueOtherCurrency: item.qty * (item.rate ?? 0),
              initialExchangeRateOtherCurrency:
                values.initialExchangeRateOtherCurrency,
              rateOtherCurrency: item.rate,
              rate: item.rate * (values.initialExchangeRateOtherCurrency ?? 0),
              totalValue: item.qty * (item.rate ?? 0),
            }));
          }
          formProps.onFinish(data);
        }
      })
      .catch(() => {
        return;
      });
  };

  const handleOnClickDelete = (id: string) => {
    mutateDelete({
      resource: 'purchase-order',
      id,
    });
  };

  const { createSupplierDrawer, handleOpenSupplierDrawer } =
    useSupplierCreateDrawer();

  const { createBankAccountDrawer, handleOpenBankAccountDrawer } =
    useBankAccountCreateDrawer();

  const {
    createItemDrawer,
    handleOpenItemDrawer,
    setResourceCreate: setResourceCreateItem,
  } = useCreateItemDrawer();

  return {
    currency,
    initialValues,
    disableItemsTab,
    itemLength: formValues?.items?.length ?? 0,
    handleOnFinish,
    handleOnClickDelete,
    percentBilled: formProps.initialValues?.percentBilled,
    percentReceived: formProps.initialValues?.percentReceived,
    grandTotal: formProps.initialValues?.grandTotal ?? calculatedGrandTotal,
    isOtherCurrency: formValues?.isOtherCurrency,
    exchangeRate: formValues?.initialExchangeRateOtherCurrency,
    createSupplierDrawer,
    handleOpenSupplierDrawer,
    createItemDrawer,
    handleOpenItemDrawer,
    setResourceCreateItem,
    createBankAccountDrawer,
    handleOpenBankAccountDrawer,
  };
};

export { usePurchaseCreate, usePurchaseDrawer };
