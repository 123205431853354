import { Space as AntdSpace } from 'antd';
import { InputMoney } from 'common/components';
import styled from 'styled-components';

import {
  FormItem,
  PaymentBox,
  SwitchBox,
  SwitchForm,
} from '../purchase-create-details.style';

const OtherCurrenciesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Space = styled(AntdSpace.Compact)`
  width: 100%;
`;

const CustomInputMoney = styled(InputMoney)`
  width: 60%;
`;

export {
  CustomInputMoney,
  FormItem,
  OtherCurrenciesWrapper,
  PaymentBox,
  Space,
  SwitchBox,
  SwitchForm,
};
