import { useModal } from '@refinedev/antd';
import { useTracking } from 'common/utils';

const useOtherCurrenciesHelp = () => {
  const { show, modalProps } = useModal();
  const { track } = useTracking();

  const handleOpenOtherCurrenciesHelp = () => {
    track('Open Other Currencies Help');
    show();
  };

  return {
    modalProps,
    handleOpenOtherCurrenciesHelp,
  };
};

export { useOtherCurrenciesHelp };
