import { CrudFilters, DataProvider } from '@refinedev/core';
import { generateSort } from '@refinedev/simple-rest';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ROUTES } from 'common/constants';
import { FMSResponseModel } from 'common/models';
import { customGenerateFilter } from 'common/utils/data-provider.util';
import { t } from 'i18next';
import { stringify } from 'query-string';
import api from 'src/api.config';

interface QueryType {
  page?: number;
  pageSize?: number;
  organizationId?: string;
  farm?: string;
  offset?: number;
  sort?: string;
  order?: string;
}

export const refineDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = api,
): Omit<
  Required<DataProvider>,
  'createMany' | 'updateMany' | 'deleteMany'
> => ({
  getList: async ({ resource, pagination, filters, meta, sorters }) => {
    const url = `${apiUrl}/${resource}`;

    const { current = 1, pageSize = 10 } = pagination ?? {};

    const queryFilters = customGenerateFilter(filters);

    const { organizationId, farm, fieldTranslate, isOptionalOrganization } =
      meta ?? {};

    if (!isOptionalOrganization && !organizationId) {
      return {
        data: [],
        total: 0,
      };
    }

    const query: QueryType = {
      organizationId: organizationId,
      farm: farm,
      pageSize: pageSize,
      page: current,
      sort: sorters && sorters[0] ? sorters[0]?.field : undefined,
      order: sorters && sorters[0] ? sorters[0]?.order : undefined,
    };

    const { data }: AxiosResponse<FMSResponseModel<any>> = await httpClient.get(
      url,
      {
        params: {
          ...query,
          ...queryFilters,
        },
      },
    );

    const dataContent = data.content ?? data;

    const dataContentWithId = dataContent.map((item, index) => {
      if (fieldTranslate && item[fieldTranslate]) {
        return {
          ...item,
          [fieldTranslate]: t([
            `uom.${item[fieldTranslate]}`,
            item[fieldTranslate],
          ]),
          id: item.id ?? item.code ?? item.name,
        };
      }
      return {
        ...item,
        id: item.id ?? item.code ?? item.name ?? `${data.pageNumber}_${index}`,
      };
    });

    const total = data.totalElements;

    return {
      data: dataContentWithId,
      total: total,
    };
  },

  getMany: async ({ resource, ids, meta }) => {
    const { headers } = meta ?? {};

    const { data } = await httpClient.get(`${apiUrl}/${resource}/`, {
      headers,
      params: {
        ...(meta?.organizationId && { organizationId: meta.organizationId }),
        ...(meta?.farmId && { farmId: meta.farmId }),
        ids,
      },
    });

    return {
      data,
    };
  },

  create: async ({ resource, variables, meta }) => {
    let url = `${apiUrl}/${resource}`;
    const { organizationId, farmId, specifier } = meta ?? {};

    if (specifier) {
      url = url.concat(`/${specifier}`);
    }

    const query: {
      organizationId?: string;
      farmId?: string;
    } = {};

    query.organizationId = organizationId;
    query.farmId = farmId;

    const { data } = await httpClient.post(url, variables, {
      params: query,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return {
      data,
    };
  },

  update: async ({ resource, id, variables, meta }) => {
    const url = `${apiUrl}/${resource}/${encodeURIComponent(id)}`;

    const { organizationId, farmId } = meta ?? {};

    const query: {
      organizationId?: string;
      farmId?: string;
    } = {};

    query.organizationId = organizationId;
    query.farmId = farmId;

    const { data } = await httpClient.put(url, variables, {
      params: query,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return {
      data,
    };
  },

  getOne: async ({ resource, meta, id }) => {
    const resourceUrl = resource ? `/${resource}` : '';
    const idUrl = id ? `/${encodeURIComponent(id)}` : '';
    const metaParentUrl = meta?.parent ? `/${meta.parent}` : '';
    const url = `${apiUrl}${resourceUrl}${idUrl}${metaParentUrl}`;

    const paramsCrudFilters: CrudFilters | undefined = meta?.params
      ? Object.keys(meta?.params).map((key) => ({
          field: key,
          value: meta?.params[key],
          operator: 'eq',
        }))
      : undefined;

    const queryFilters = customGenerateFilter(paramsCrudFilters);
    const { data } = await httpClient.get(url, {
      params: {
        ...(meta?.organizationId && { organizationId: meta.organizationId }),
        ...(meta?.farmId && { farmId: meta.farmId }),
        ...queryFilters,
      },
      responseType: meta?.responseType,
    });

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables, meta }) => {
    const url = `${apiUrl}/${resource}/${encodeURIComponent(id)}`;

    const { organizationId, farmId } = meta ?? {};

    const query: {
      organizationId?: string;
      farmId?: string;
    } = {};

    query.organizationId = organizationId;
    query.farmId = farmId;

    const { data } = await httpClient.delete(url, {
      data: variables,
      params: query,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sorters, payload, query, meta }) => {
    let requestUrl = `${url}?`;

    const { organizationId, farmId } = meta ?? {};

    const queryMeta: {
      organizationId?: string;
      farmId?: string;
    } = {};

    queryMeta.organizationId = organizationId;
    queryMeta.farmId = farmId;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(','),
          _order: _order.join(','),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = customGenerateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (queryMeta) {
      requestUrl = `${requestUrl}&${stringify(queryMeta)}`;
    }

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload);
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
